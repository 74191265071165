import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "../styles/button.css";
import { limitHeight, limitWidth } from "../helpers/sizes";

const ButtonOptions = ({
  callBackSize,
  imageSize,
  isImageUploaded,
  onChangeSelectedBtn,
}) => {
  // FIXME: learn why a local copy of this state is needed and not just injected directly as prop
  const [selectedBtn, setSelectedBtn] = useState("");

  const btnClass = (label) => {
    if (label === selectedBtn) return "btn btn-selected";
    return "btn";
  };

  const sendSize = (width, height) => {
    const aspect = width / height;
    const ratio = convertToAspectRatio(width, height);
    const size = { width, height };

    localStorage.setItem(
      "crop_size",
      JSON.stringify({ resolution: `${limitWidth(width)}x${limitHeight(height)}`, size, ratio })
    );

    callBackSize({
      aspect,
      size,
    });
  };

  const sendOriginalSize = () => {
    const { width, height } = imageSize;

    const aspect = width / height;
    const ratio = convertToAspectRatio(width, height);
    const size = { width, height };

    localStorage.setItem(
      "crop_size",
      JSON.stringify({
        resolution: `${limitWidth(width)}x${limitHeight(height)}`,
        size,
        ratio,
      })
    );

    callBackSize({
      aspect,
      size,
    });
  };

  const isDisabledBtn = (width, height) => {
    if (imageSize.width < width) return true;
    else if (imageSize.height < height) return true;
    else return false;
  };

  const isBetween = (minWidth, maxWidth, minHeight, maxHeight) => {
    const { width, height } = imageSize;

    return (
      minWidth <= width &&
      width <= maxWidth &&
      minHeight <= height &&
      height <= maxHeight
    );
  };

  const convertToAspectRatio = (width, height) => {
    const gcd = (a, b) => {
      return b === 0 ? a : gcd(b, a % b);
    };

    var ratio = gcd(width, height);

    var aspectRatio =
      width === 0 && height === 0
        ? "0:0"
        : `${width / ratio}:${height / ratio}`;

    return aspectRatio;
  };

  useEffect(() => {
    if (isImageUploaded) {
      localStorage.setItem(
        "crop_size",
        JSON.stringify({
          size: imageSize,
          resolution: `${limitWidth(imageSize.width)}x${limitHeight(imageSize.height)}`,
          ratio: convertToAspectRatio(imageSize.width, imageSize.height),
        })
      );
      setSelectedBtn("source");
    } else {
      setSelectedBtn("");
    }
  }, [isImageUploaded, imageSize]);

  const Option = ({ id, width, height, label, overrideLabel, disabled }) => {
    return (
      <button
        className={clsx(btnClass(id), {
          "is-active": id === selectedBtn,
        })}
        onClick={() => {
          sendSize(width, height);
          setSelectedBtn(id);
          onChangeSelectedBtn(id);
        }}
        disabled={
          disabled !== undefined ? disabled : isDisabledBtn(width, height)
        }
      >
        {overrideLabel
          ? overrideLabel
          : `${label} - ${width}x${height} (${convertToAspectRatio(
              width,
              height
            )})`}
      </button>
    );
  };

  const buttonOptions = [
    {
      id: "desktop-header",
      type: "option",
      width: 840,
      height: 640,
      label: "Download",
    },
    {
      id: "meta",
      type: "option",
      width: 1024,
      height: 512,
      label: "Meta",
    },
    {
      id: "lightbox-1920x1200@dpr2",
      type: "option",
      width: imageSize.width,
      height: imageSize.height,
      label: "Lightbox Desktop @dpr2",
      disabled: !isBetween(3840, 3840, 2400, 16382) || !isImageUploaded,
      overrideLabel: `Lightbox Desktop @dpr2 - ${
        imageSize.width ? limitWidth(imageSize.width) : 3840
      }x${imageSize.height ? limitHeight(imageSize.height) : 2400} (${
        imageSize.width
          ? convertToAspectRatio(imageSize.width, imageSize.height)
          : convertToAspectRatio(3840, 2400)
      })`,
    },
    {
      id: "lightbox-412x8191@dpr2",
      type: "option",
      width: imageSize.width,
      height: imageSize.height,
      label: "Lightbox Mobile @dpr2",
      disabled: !isBetween(824, 824, 1830, 16384) || !isImageUploaded,
      overrideLabel: `Lightbox Mobile @dpr2 - ${
        imageSize.width ? limitWidth(imageSize.width) : 824
      }x${imageSize.height ? limitHeight(imageSize.height) : 1830} (${
        imageSize.width
          ? convertToAspectRatio(imageSize.width, imageSize.height)
          : convertToAspectRatio(824, 1830)
      })`,
    },
    {
      id: "website-template",
      type: "option",
      width: 2112,
      height: 1280,
      label: "Website Template",
    },
    {
      id: "in-2400x1350",
      type: "option",
      width: 2400,
      height: 1350,
      label: "Inline",
    },
    {
      id: "in-1200x1350",
      type: "option",
      width: 1200,
      height: 1350,
      label: "Inline",
    },
    {
      id: "how-800x800",
      type: "option",
      width: 800,
      height: 800,
      label: "How To",
    },
    {
      id: "how-288x288",
      type: "option",
      width: 288,
      height: 288,
      label: "How To",
    },
    {
      id: "how-144x144",
      type: "option",
      width: 144,
      height: 144,
      label: "How To",
    },
    {
      id: "dns-420x320",
      type: "option",
      width: 420,
      height: 320,
      label: "Snippet",
    },
  ];

  return (
    <div className="btn-group">
      <div className="btn-wrapper">
        <button
          className={btnClass("source")}
          onClick={() => {
            sendOriginalSize();
            setSelectedBtn("source");
            onChangeSelectedBtn("source");
          }}
          disabled={isDisabledBtn(2400, 2400)}
        >
          Source Image {`${imageSize.width}x${imageSize.height}`} (
          {convertToAspectRatio(imageSize.width, imageSize.height)})
        </button>
      </div>

      {buttonOptions.map((button) => {
        const { id, width, height, label, type, disabled, overrideLabel } =
          button;
        if (type === "divider") {
          return (
            <button key={id} className={"divider"}>
              Divider
            </button>
          );
        }

        return (
          <div className="btn-wrapper" key={id}>
            <Option
              key={id}
              id={id}
              width={width}
              height={height}
              label={label}
              disabled={disabled}
              overrideLabel={overrideLabel}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ButtonOptions;
